import { ProjectStatus, DisplayTypes } from "@/interface/projectsInterface";
import { ProjectStoreClass } from "./index";
import i18n from "@/i18n";
import Project from "@/model/Project";

export default {
  GetAllScenes: (state: ProjectStoreClass) => {
    return state.projectScenes;
  },
  GetActiveScene: (state: ProjectStoreClass) => {
    return state.activeScene;
  },
  GetAllProjects: (state: ProjectStoreClass) => {
    return state.ProjectsList;
  },
  GetAllArchivedProjects: (state: ProjectStoreClass) => {
    return state.archivedProjectsList.allMainProjects;
  },
  GetAllArchivedSubProjects: (state: ProjectStoreClass) => {
    return (id: number) => state.archivedProjectsList.subProjects(id);
  },
  GetAllStatus(state: ProjectStoreClass) {
    return state.projectStatusList.map((status: ProjectStatus) => {
      status.label = i18n.t(`project.statusOption.${status.name}`);
      return status;
    });
  },
  GetAllMainProjects: (state: ProjectStoreClass) => {
    return state.ProjectsList.filter((item: Project) => item.type === null || item.type.id === 1);
  },
  GetAllActiveMainProjects: (state: ProjectStoreClass) => {
    return state.ProjectsList.filter(
      (item: Project) => (item.type === null || item.type.id === 1) && item.status?.name === "active"
    );
  },
  GetProjectById(state: ProjectStoreClass) {
    return (projectId: string) => state.ProjectsList.find((project: any) => project.id == projectId);
  },
  GetSubProjectsList(state: ProjectStoreClass, id: number) {
    return (id: number) => state.ProjectsList.filter((project: Project) => project.parent?.id === id);
  },
  GetActiveSubProjectsList(state: ProjectStoreClass, id: number) {
    return (id: number) =>
      state.ProjectsList.filter((project: Project) => project.parent?.id === id && project.status?.name === "active");
  },
  GetAllUserOption(state: ProjectStoreClass) {
    return state.userInProject;
  },
  GetCurrentProject(state: ProjectStoreClass): Project {
    return state.currentProject;
  },
  // Sections states for the map
  GetProjectSectionsStatesForMap(state: ProjectStoreClass) {
    return state.allSectionsStatesForTheMap;
  },
  // types state
  GetProjectTypes(state: ProjectStoreClass) {
    // TODO Make the translation here?
    return state.projectTypes;
  },
  GetDisplayTypesList(state: ProjectStoreClass) {
    return state.displayTypesList.map((displayOption: DisplayTypes) => {
      displayOption.label = i18n.t(`project.displayTypesOption.${displayOption.name}`);
      return displayOption;
    });
  },
  // Sections
  GetSectionList(state: ProjectStoreClass) {
    if (state.currentProject.isRZP) {
      return state.currentProjectSectionList
        .filter((item) => item.direction === "DEFAULT")
        .sort((a, b) => (a.ordinalNumber > b.ordinalNumber ? -1 : 1));
    } else {
      return state.currentProjectSectionList;
    }
  },
  GetAllSectionInProject(state: ProjectStoreClass) {
    if (state.currentProject.isRZP) {
      return state.currentProjectSectionList.reverse();
    } else {
      return state.currentProjectSectionList;
    }
  },
  GetSectionTypes(state: ProjectStoreClass) {
    return state.sectionTypesList.map((sectionType: DisplayTypes) => {
      sectionType.label = i18n.t(`project.sectionOptions.${sectionType.name}`);
      return sectionType;
    });
  },
  GetSectionSubTypes(state: ProjectStoreClass) {
    return state.sectionSubTypes;
  },
  GetCurrentSectionDetails(state: ProjectStoreClass) {
    return state.sectionDetails;
  },
  GetCurrentSectionDetailsStateModal(state: ProjectStoreClass) {
    if (state.sectionDetails.state && state.sectionDetails.state.fpuSettings) {
      state.sectionDetails.state.fpuSettings.filename = state.sectionDetails.state.fpuSettings.filename
        ? state.sectionDetails.state.fpuSettings.filename.replace(".bmp", "")
        : "";
      return state.sectionDetails.state;
    }
    return null;
  },
  GetSectionIntervalList(state: ProjectStoreClass) {
    return state.currentSectionInterval;
  },
  // Sections state
  GetCurrentSectionState(state: ProjectStoreClass) {
    return state.currentSectionState;
  },
  //Section Display State
  GetCurrentSectionDisplayState(state: ProjectStoreClass) {
    return state.currentDisplayState;
  },
  GetCurrentDisplayInformation(state: ProjectStoreClass) {
    return state.displayInformation;
  },
  GetManualOverrideOptionsFromState(state: ProjectStoreClass) {
    return state.manualOverrideSignOptions;
  },
  GetCurrentThreshold(state: ProjectStoreClass) {
    return state.currentProjectThreshold;
  },
  GetCurrentThresholdTable(state: ProjectStoreClass) {
    return state.currentProjectThresholdTable;
  },
  // Camera Section
  GetCurrentPicture(state: ProjectStoreClass) {
    return state.cameraSectionLatestPicture;
  },
  GetCurrentCameraSectionSettings(state: ProjectStoreClass) {
    return state.currentCameraSectionSettings;
  },
  // Track the row open in the  project list
  GetCurrentProjectOpen(state: ProjectStoreClass) {
    return state.currentRowOpenInTheProjectList;
  },
  GetCurrentMainProjectOpen(state: ProjectStoreClass) {
    return state.currentRowOpenMainProjectInTheProjectList;
  },
  /*----------  Get all available countrys in the system  ----------*/
  GetAllAvailableCountrys(state: ProjectStoreClass) {
    return state.allAvailableCountrysOptions;
  },
  /*----------  RZP Type  ----------*/
  GetAllOppositeSections(state: ProjectStoreClass) {
    if (!state.currentProject.isRZP) return [];
    return state.currentProjectSectionList
      .filter((item) => item.direction === "OPPOSITE")
      .sort((a, b) => (a.ordinalNumber > b.ordinalNumber ? -1 : 1))
      .reverse();
  },
  /*----------  Get latest traffic count  ----------*/
  GetLatestTrafficCount(state: ProjectStoreClass) {
    return state.currentSectionTrafficCount.formattedData;
  },
  /*----------  Check if current user can view project details  ----------*/
  GetCurrentUserViewProjectDetails(state: ProjectStoreClass) {
    return state.currentUserCanViewProjectDetails;
  },
  GET_MODAL_INFO(state: ProjectStoreClass) {
    return state.modalInfo;
  }
};
