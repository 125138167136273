import { Evaluator } from "./modules/EvaluatorModule";
import { MainProject } from "./modules/MainProject";
import { SystemLogs } from "./modules/SystemLogs";
import Vuex from "vuex";
import { AppState } from "@/stores/state";
import roles from "@/stores/roles";
import currentUser from "@/stores/modules/currentUser";
import inventoryDevices from "@/stores/modules/inventoryDevices";

import projects from "@/stores/modules/project";
import UserList from "@/stores/modules/UserList";
import Dashboard from "@/stores/modules/DashboardData";
import ProjectProfilesStore from "@/stores/modules/ProjectProfilesStore";
import ProjectReportGenerator from "@/stores/modules/ProjectReportGenerator";
import RoadZipperStore from "@/stores/modules/RoadZipperStore";
import { Theme } from "@/stores/modules/Theme";

//import createPersistedState from "vuex-persistedstate";

export const createStore = () =>
  new Vuex.Store<AppState>({
    modules: {
      Dashboard,
      roles,
      currentUser,
      inventoryDevices,
      projects,
      UserList,
      SystemLogs,
      ProjectProfilesStore,
      ProjectReportGenerator,
      RoadZipperStore,
      Theme,
      MainProject,
      Evaluator
    }
    // plugins: [
    //     createPersistedState({
    //         key: "mainstream"
    //     })
    // ]
  });
