import Project from "@/model/Project";
import { Projects } from "@/services/projectService";
import { AppState } from "@/stores/state";
import { LRectangle } from "vue2-leaflet";
import { ActionContext, ActionTree, MutationTree, GetterTree, Module } from "vuex";
import MainProjectService from "@/services/MainProjectService";
import { MainProjectSection, OpenInformationModal, CameraSectionDetails } from "@/model/MainProject/MainProjectSection";

const baseModal: OpenInformationModal = {
  projectId: 0,
  sectionId: 0,
  trafficReport: false,
  boardPosition: "deviceOne",
  projectType: {
    isVWA: false,
    isCWU: false,
    isFPU: false,
    isRZP: false,
    isSCENE: false,
    isVSU: false,
    isMq3Aq32SW: false,
    isMq4Aq42SWDEGES: false,
    isMq3Aq32023: false,
    isMq4Aq42SW: false,
    isMq5Aq52SW: false,
    isMq1Aq12SW: false,
    isCwuCascaded: false,
  }
};
const baseCameraModal: CameraSectionDetails = {
  id: 0
};
class MainProjectClass {
  mainProjectDirections: Map<String, Array<MainProjectSection>> = new Map<String, Array<MainProjectSection>>();
  mainProjectDetails = new Project();
  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }

  /*----------  Get all direction Unique project id's  ----------*/
  get getSubProjectIds(): Array<number> {
    const projectIdList: Set<number> = new Set();

    this.mainProjectDirections.forEach((v: MainProjectSection[], k: String): void => {
      v.forEach((item) => projectIdList.add(item.project.id));
    });

    return Array.from(projectIdList);
  }

  setDirections(directions: Record<string, MainProjectSection[]>): void {
    this.mainProjectDirections = new Map(Object.entries(directions));
  }
  setMainProjectDetails(project: Project) {
    this.mainProjectDetails = project;
  }
}
export class MainProjectState {
  currentMainProject = new MainProjectClass();
  deviceModalOpen: OpenInformationModal = baseModal;
  cameraSectionDetails: CameraSectionDetails = baseCameraModal;
}
const getters: GetterTree<MainProjectState, AppState> = {
  GETTER_MAIN_DIRECTIONS(state) {

    return state.currentMainProject.mainProjectDirections;
  },
  GETTER_MAIN_PROJECT_DETAILS(state) {

    return state.currentMainProject.mainProjectDetails;

  },
  GET_MODAL_OPEN_INFORMATION(state) {
    return state.deviceModalOpen;
  },
  GET_MODAL_CAMERA_OPEN_INFORMATION(state) {
    return state.cameraSectionDetails;
  }
};

const mutations: MutationTree<MainProjectState> = {
  SET_CURRENT_MAIN_PROJECT(state: MainProjectState, MAIN_PROJECT) {
    state.currentMainProject = MAIN_PROJECT;
  },
  SET_CURRENT_MODAL_OPEN(state: MainProjectState, modalInformation: OpenInformationModal = baseModal) {
    state.deviceModalOpen = modalInformation;
  },
  SET_CURRENT_MODAL_CAMERA_OPEN(state: MainProjectState, modalCameraDetails: CameraSectionDetails = baseCameraModal) {
    state.cameraSectionDetails = modalCameraDetails;
  }
};

const actions: ActionTree<MainProjectState, AppState> = {
  async ACTION_GET_MAIN_PROJECT({ commit, dispatch }: ActionContext<MainProjectState, AppState>, ID: number) {
    try {
      // await Projects.DetailsProject(ID + "");
      const res = await MainProjectService.getMainProjectById(ID);
      if (res.status === 200) {
        const mainProject = new MainProjectClass();
        mainProject.setMainProjectDetails(new Project(mainProject));
        mainProject.setDirections(res.data);
        commit("SET_CURRENT_MAIN_PROJECT", mainProject);
        dispatch("Evaluator/FETCH_MAIN_SUB_PROJECT_VOLUMES", mainProject.getSubProjectIds, { root: true });

        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },

  async ACTION_SORT_SECTIONS_BY_POSITION(
    { dispatch }: ActionContext<MainProjectState, AppState>,
    { projectId, direction, toggleDirection }: { projectId: number; direction: String; toggleDirection: String }
  ) {
    try {
      const res = await MainProjectService.sortSectionsByPositions(projectId, direction, toggleDirection);
      if (res.status === 204) {
        return res;
      }
    } catch (error) {
      return error;
    }
  },

  ACTION_SET_OPEN_MODAL_INFORMATION(
    { commit }: ActionContext<MainProjectState, AppState>,
    modalInformation: OpenInformationModal
  ) {
    commit("SET_CURRENT_MODAL_OPEN", modalInformation);
  },
  ACTION_SET_OPEN_MODAL_CAMERA(
    { commit }: ActionContext<MainProjectState, AppState>,
    modalCameraDetails: CameraSectionDetails
  ) {
    commit("SET_CURRENT_MODAL_CAMERA_OPEN", modalCameraDetails);
  }
};

const namespaced: boolean = true;
export const MainProject: Module<MainProjectState, AppState> = {
  namespaced,
  state: new MainProjectState(),
  actions,
  getters,
  mutations
};
