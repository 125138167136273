import ArchiveProjectsList from "@/model/ArchiveProjectsList";
import {
  DisplayTypes,
  SectionDisplayState,
  DisplayInformation,
  Scene,
  ProjectStatus,
  Interval,
  CameraSettings,
  CurrentRowOpen,
  ModalInformation,
  Types
} from "@/interface/projectsInterface";
import Section from "@/model/ModelSection";
import Project, { ProjectThreshold, TrafficCountStats } from "@/model/Project";
import SectionState from "@/model/ModelSectionState";
import ModelUser from "@/model/ModelUser";
import { Type } from "@/interface/DeviceInterface";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export class ProjectStoreClass {
  sectionDetails: Section = new Section();
  ProjectsList: Project[] = [];
  userInProject: ModelUser[] = [];
  currentProject: Project = new Project();
  currentProjectSectionList: Section[] = [];
  projectStatusList: ProjectStatus[] = [];
  projectTypes: Types[] = [];
  sectionTypesList: DisplayTypes[] = [];
  sectionSubTypes: Types[] = [];
  currentSectionInterval: Interval[] = [];
  displayTypesList: DisplayTypes[] = [];
  currentDisplayState: SectionDisplayState | null = null;
  displayInformation: DisplayInformation | null = null;
  projectScenes: Scene[] = [];
  activeScene: Scene | null = null;
  currentSectionState: SectionState = new SectionState();
  allSectionsStatesForTheMap: SectionState[] = [];
  manualOverrideSignOptions = [];
  currentProjectThreshold: ProjectThreshold = new ProjectThreshold();
  currentProjectThresholdTable = [];
  cameraSectionLatestPicture: null | Blob = null;
  currentCameraSectionSettings: CameraSettings | null = null;
  currentRowOpenInTheProjectList: CurrentRowOpen | null = null;
  currentRowOpenMainProjectInTheProjectList: CurrentRowOpen | null = null;
  allAvailableCountrysOptions: {}[] = [];
  currentSectionTrafficCount: TrafficCountStats = new TrafficCountStats();

  archivedProjectsList: ArchiveProjectsList = new ArchiveProjectsList();
  currentUserCanViewProjectDetails: boolean = false;
  modalInfo: ModalInformation = {
    position: "boardOne",
    sectionId: 0
  };
}

export default {
  namespaced: true,
  state: new ProjectStoreClass(),
  getters,
  actions,
  mutations
};
