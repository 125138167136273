import { TrafficCountDetails, TrafficVolumeData } from "@/services/evaluatorService";
import { AppState } from "@/stores/state";
import { ActionContext, ActionTree, MutationTree, GetterTree, Module } from "vuex";

export class Evaluator_module {
  currentProjectTrafficVolumeData: TrafficVolumeData[] = [];
  deviceHistoricalData: Record<string, number[]> = {};

  currentMainSubProjectsVolume: Map<number, TrafficVolumeData[]> = new Map<number, TrafficVolumeData[]>();

  get getCurrentTrafficVolume() {
    return this.currentProjectTrafficVolumeData;
  }
  get getHistoricalData() {
    return this.deviceHistoricalData;
  }
  public getVolumeBySection(sectionId: number): TrafficVolumeData {
    return this.findVolume(this.currentProjectTrafficVolumeData, sectionId);
  }

  public getVolumeBySectionOnMainProject(sectionId: number, projectId: number): TrafficVolumeData {
    const data = this.currentMainSubProjectsVolume.get(projectId);
    return this.findVolume(data || [], sectionId);
  }

  private findVolume(volumes: TrafficVolumeData[], sectionId: number): TrafficVolumeData {
    return (
      volumes.find((vol) => vol.sectionId === sectionId) || {
        sectionId,
        totalVolumePerHour: 0
      }
    );
  }
  public setProjectVolume(data: TrafficVolumeData[]) {
    this.currentProjectTrafficVolumeData = data;
  }

  public setMainProjectVolume(data: Map<number, TrafficVolumeData[]>) {
    this.currentMainSubProjectsVolume = data;
  }
  public setDeviceHistoricalData(data: Record<string, number[]>) {
    this.deviceHistoricalData = {
      batteryVoltage: data.batteryVoltage,
      solarArray: data.solarArray,
      chargingCurrent: data.chargingCurrent,
      dischargingCurrent: data.dischargingCurrent,
      brightness: data.brightness
    };
  }
}
const getters: GetterTree<Evaluator_module, AppState> = {
  GET_SECTION_TRAFFIC_VOLUME(state: Evaluator_module) {
    return (sectionId: number) => state.getVolumeBySection(sectionId);
  },
  GET_SECTION_TRAFFIC_VOLUME_MAIN_PROJECT(state: Evaluator_module) {
    return (sectionId: number, projectId: number) => state.getVolumeBySectionOnMainProject(sectionId, projectId);
  },
  GET_DEVICE_HISTORICAL_DATA(state: Evaluator_module) {
    return state.getHistoricalData;
  }
};

const mutations: MutationTree<Evaluator_module> = {
  SET_PROJECT_VOLUME(state: Evaluator_module, data: TrafficVolumeData[]) {
    state.setProjectVolume(data);
  },
  SET_MAIN_PROJECT_VOLUMES(state: Evaluator_module, data: Map<number, TrafficVolumeData[]>) {
    state.setMainProjectVolume(data);
  },
  SET_DEVICE_HISTORICAL_DATA(state: Evaluator_module, data: Record<string, number[]>) {
    state.setDeviceHistoricalData(data);
  },
  RESET_DEVICE_HISTORICAL_DATA(state: Evaluator_module, data: Record<string, number[]> = {}) {
    state.setDeviceHistoricalData(data);
  }
};

const actions: ActionTree<Evaluator_module, AppState> = {
  async FETCH_CURRENT_PROJECT_VOLUME({ commit }: ActionContext<Evaluator_module, AppState>, projectId: number) {
    try {
      const res = await TrafficCountDetails.GetVolumeByProject(projectId);
      if (res.status === 200) {
        commit("SET_PROJECT_VOLUME", res.data);
        commit("SET_MAIN_PROJECT_VOLUMES", new Map());
      }
    } catch (error) {
      console.log(error);
    }
  },
  async FETCH_MAIN_SUB_PROJECT_VOLUMES({ commit }: ActionContext<Evaluator_module, AppState>, ProjectIds: number[]) {
    try {
      commit("SET_PROJECT_VOLUME", []);

      const mainProjectVolumeData = new Map<number, TrafficVolumeData[]>();

      const promises = ProjectIds.map(async (id: number) => {
        const res = await TrafficCountDetails.GetVolumeByProject(id);
        if (res.status === 200) {
          mainProjectVolumeData.set(id, res.data);
        }
      });
      await Promise.all(promises);

      commit("SET_MAIN_PROJECT_VOLUMES", mainProjectVolumeData);
    } catch (error) {
      console.log(error);
    }
  },
  async FETCH_DEVICE_HISTORICAL_DATA(
    { commit }: ActionContext<Evaluator_module, AppState>,
    { deviceId, timeFrame }: { deviceId: string; timeFrame: string }
  ) {
    try {
      const res = await TrafficCountDetails.GetDeviceHistoricalData(deviceId, timeFrame);
      if (res.status === 200) {
        commit("SET_DEVICE_HISTORICAL_DATA", res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const namespaced: boolean = true;
export const Evaluator: Module<Evaluator_module, AppState> = {
  namespaced,
  state: new Evaluator_module(),
  actions,
  getters,
  mutations
};
