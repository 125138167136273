import { AppState } from "@/stores/state";
import { ActionContext, ActionTree, MutationTree, GetterTree, Module } from "vuex";
import { ProjectTypeRZP } from "@/services/projectService.ts";

interface SceneOption {
  sectionTitle: string;
  filename: string;
  direction: String;
  stateId: number;
}
class allSceneOptionsClass {
  allScenes: Array<SceneOption> = [];
  allPossibleScenes: Array<any> = [0, 1, 2, 3, 4];
  constructor(sceneArray?: any) {
    if (sceneArray) {
      this.allScenes = sceneArray;
    }
  }
  get allFormattedScenes(): object {
    const formattedScenes: { [key: string]: any } = {};
    if (!this.allScenes.length) {
      return formattedScenes;
    }

    this.allPossibleScenes.forEach((item) => {
      formattedScenes[item] = this.allScenes
        .filter((scene) => scene.stateId === item)
        .sort((a, b) => (a.direction > b.direction ? -1 : 1));
    });
    return formattedScenes;
  }

  getAllPossibleSceneOptions() {
    if (!this.allScenes.length) return [];

    const allPossibleScenesDynamic: Array<number> = this.allScenes.map((scene) => {
      return scene.stateId;
    });
    this.allPossibleScenes = Array.from(new Set(allPossibleScenesDynamic));
  }
}
export class RoadZipperProject {
  allSceneOption = new allSceneOptionsClass();
  RZPActiveScene: object = {};
}
const getters: GetterTree<RoadZipperProject, AppState> = {
  ALL_SCENES_OPTIONS(state: RoadZipperProject) {
    return state.allSceneOption.allFormattedScenes;
  },
  RZPActiveScene(state: RoadZipperProject) {
    return state.RZPActiveScene;
  }
};

const mutations: MutationTree<RoadZipperProject> = {
  SetSceneOption(state: RoadZipperProject, allSceneOptions: allSceneOptionsClass) {
    state.allSceneOption = allSceneOptions;
  },
  SetRZPActiveScene(state: RoadZipperProject, activeScene: object) {
    state.RZPActiveScene = activeScene;
  }
};

const actions: ActionTree<RoadZipperProject, AppState> = {
  async GET_ALL_SCENES_RZP_PROJECT({ commit }: ActionContext<RoadZipperProject, AppState>, projectId: string) {
    try {
      const res = await ProjectTypeRZP.GetAllScenesOptions(projectId);

      if (res.status === 200) {
        const sceneArray = new allSceneOptionsClass(res.data.data);
        commit("SetSceneOption", sceneArray);
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },
  async GetRZPActiveScene({ commit }: ActionContext<RoadZipperProject, AppState>, projectId) {
    try {
      const res = await ProjectTypeRZP.GetActiveScene(projectId);
      if (res.status !== 200) return res;
      commit("SetRZPActiveScene", res.data);
      return res;
    } catch (error) {
      return error;
    }
  },
  async RZPSceneChange(
    { dispatch }: ActionContext<RoadZipperProject, AppState>,
    { projectId, stateId }: { projectId: string; stateId: string }
  ) {
    try {
      const res = await ProjectTypeRZP.SetActiveScene(projectId, stateId);
      dispatch("GetRZPActiveScene", projectId);
      if (res.status !== 200) return res;
      return res;
    } catch (error) {
      return error;
    }
  }
};

const namespaced: boolean = true;
const RoadZipperStore: Module<RoadZipperProject, AppState> = {
  namespaced,
  state: new RoadZipperProject(),
  actions,
  getters,
  mutations
};
export default RoadZipperStore;
