import { AppState } from "@/stores/state";
import { ActionContext, ActionTree, MutationTree, GetterTree, Module } from "vuex";

export class Theme_store {
  theme: string | null = "dark";
  isSystemInMaintenanceMode = false;
  isSystemProductiveSystem = 1;

  get getCurrentTheme() {

    const theme = localStorage.getItem("theme");
    if (!theme) {
      localStorage.setItem("theme", "dark");
    }
    this.theme = localStorage.getItem("theme");

    return this.theme;
  }

  public setTheme(theme: string) {
    localStorage.setItem("theme", theme);
    this.theme = localStorage.getItem("theme");
  }

  get isInMaintenanceMode(): boolean {
    return this.isSystemInMaintenanceMode;
  }

  get isProductiveSystem(): number {
    return this.isSystemProductiveSystem;
  }

  public setMaintenanceMode(state: boolean) {
    this.isSystemInMaintenanceMode = state;
  }

  public setProductiveSystem(state: number) {
    this.isSystemProductiveSystem = state;
  }
}

const getters: GetterTree<Theme_store, AppState> = {
  GET_CURRENT_THEME(state: Theme_store) {
    return state.getCurrentTheme;
  },
  GET_MAINTENANCE_MODE(state: Theme_store) {
    return state.isInMaintenanceMode;
  },
  GET_PRODUCTIVE_SYSTEM(state: Theme_store) {
    return state.isProductiveSystem;
  }
};

const mutations: MutationTree<Theme_store> = {
  SET_THEME(state: Theme_store, theme: string) {
    state.setTheme(theme);
  },
  SET_SYSTEM_STATE(state: Theme_store, systemState: boolean) {
    state.setMaintenanceMode(systemState);
  },
  SET_PRODUCTIVE_SYSTEM(state, systemState: number) {
    state.setProductiveSystem(systemState);
  }
};

const actions: ActionTree<Theme_store, AppState> = {
  async TOGGLE_THEME({ commit }: ActionContext<Theme_store, AppState>, theme: string) {
    commit("SET_THEME", theme);
  },
  UPDATE_SYSTEM_STATE({ commit }: ActionContext<Theme_store, AppState>, systemState: boolean) {
    commit("SET_SYSTEM_STATE", systemState);
  },
  UPDATE_PRODUCTIVE_SYSTEM({ commit }: ActionContext<Theme_store, AppState>, systemState: boolean) {
    commit("SET_PRODUCTIVE_SYSTEM", systemState);
  }

};

const namespaced: boolean = true;
export const Theme: Module<Theme_store, AppState> = {
  namespaced,
  state: new Theme_store(),
  actions,
  getters,
  mutations
};
