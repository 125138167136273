import axios from "axios";
import router from "@/router";

const SystemLogsService = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_LOGGER,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async GetAllUsersLogs(pageNumber: number, pageSize: number) {
    const res = await this.createAxiosInstance().get(`/api/v1/app_log?page=${pageNumber}&pageSize=${pageSize}`);
    return res;
  }
};

export { SystemLogsService };
