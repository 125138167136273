import { AppState } from "@/stores/state";
import { ActionContext, ActionTree, GetterTree, MutationTree } from "vuex";
import DashboardSections from "@/model/ModelDashBoardSections";
import ManualDevice from "@/model/ModelDashBoardSections";
import { UsersSessionDetails } from "@/model/Dashboard/ModelUsersHealth";

import { Projects } from "@/services/projectService";
import { InventoryStates, InventoryStatesHealth } from "@/services/InventoryService";
import ibomadeUserService from "@/services/ibomadeUserService";
import i18n from "@/i18n";
import router from "@/router";
import {
  ProjectStatusDashboard,
  BoardStatusDashboard,
  DeviceStatusDashboard,
  DeviceAdditionalStatusDashboard,
  CongestionProject
} from "@/interface/DashboardDataInterface";

class StateCount {
  state: string = "";
  count: number = 0;
  constructor(state: string) {
    this.state = state;
  }
}

class ProjectHealthInterface {
  boardStatusList: Array<DashboardSections> = [];
  projectStatus: ProjectStatusDashboard = {
    planning: 0,
    active: 0,
    finished: 0,
    archived: 0
  };

  boardStatus: BoardStatusDashboard = {
    online: 0,
    offline: 0
  };

  deviceStatus: DeviceStatusDashboard = {
    ok: 0,
    alert: 0,
    warning: 0,
    waiting: 0
  };
  deviceAdditionalStatus: DeviceAdditionalStatusDashboard = {
    support: 0,
    manual: 0
  };

  manualDeviceList: Array<ManualDevice> = [];
  projectsInCongestion: Array<CongestionProject> = [];
  totalPages: number = 1;
  nextPage: number = 0;
  previousPage: number = 0;
  currentPage: number = 0;

  constructor(health?: any) {
    Object.assign(this, health);
  }

  get getPagination(): object {
    return {
      totalPages: this.totalPages,
      nextPage: this.nextPage,
      previousPage: this.previousPage,
      currentPage: this.currentPage
    };
  }

  get projectStatusTranslated() {
    const translatedState: { [label: string]: number } = {};
    Object.keys(this.projectStatus).forEach((item) => {
      const label: string = i18n.t("dashboard.metricsProjects." + item).toString();
      translatedState[label] = (this as any).projectStatus[item];
    });
    return translatedState;
  }

  get deviceStatusTranslated() {
    const translatedState: { [label: string]: number } = {};
    Object.keys(this.deviceStatus).forEach((item) => {
      const label: string = i18n.t("dashboard.metricsDevices." + item).toString();
      translatedState[label] = (this as any).deviceStatus[item];
    });
    return translatedState;
  }

  get deviceAdditionalStatusTranslated() {
    const translatedState: { [label: string]: number } = {};
    Object.keys(this.deviceAdditionalStatus).forEach((item) => {
      const label: string = i18n.t("dashboard.metricsDevices." + item).toString();
      translatedState[label] = (this as any).deviceAdditionalStatus[item];
    });
    return translatedState;
  }
}
class DeviceStateMetric {
  stateCounts: Array<StateCount> = [];
  totalCount: number = 0;
  allStates: Array<string> = [];
  constructor(deviceStateMetrics?: any) {
    if (deviceStateMetrics) {
      this.stateCounts = deviceStateMetrics.stateCounts;
      this.totalCount = deviceStateMetrics.totalCount;
    }
  }
  setAllStates(allState: Array<string>) {
    this.allStates = allState;
  }
  transformToHealthChartItems() {
    const object: { [key: string]: any } = {};
    for (const item of this.allStates) {
      object[i18n.t("dashboard.metricsDevices." + item).toString()] = 0;
    }
    if (this.stateCounts) {
      return this.stateCounts.reduce((result: { [key: string]: any }, item) => {
        result[i18n.t("dashboard.metricsDevices." + item.state).toString()] = item.count;
        return result;
      }, object);
    }
  }

  getTotalDevices() {
    if (this.totalCount) {
      return this.totalCount;
    }
  }
  getColorForChartItems() {
    const colors = ["#59f9b1", "#4a8555", "#00D8FF", "#808080", "#FFC107", "#e5e5e5"];
    if (this.allStates.length > colors.length) {
      const numberOfMoreColors = this.allStates.length - colors.length;
      for (let index = 0; index < numberOfMoreColors; index++) {
        colors.push(`hsla(${index * 20 + 160}, 100%, ${index * 11 + numberOfMoreColors + 10}%, 1)`);
      }
    }
    return colors;
  }
}
class UsersStats {
  sessionDetailList: Array<UsersSessionDetails> = [];
  constructor(usersStats?: any) {
    Object.assign(this, usersStats);
  }
}
export class Dashboard_state {
  allProjectsSectionsHealth = new ProjectHealthInterface();
  deviceStateMetric = new DeviceStateMetric();
  usersStats = new UsersStats();
}
const getters: GetterTree<Dashboard_state, AppState> = {
  GetAllProjectSectionsHealth(state: Dashboard_state) {
    return state.allProjectsSectionsHealth.boardStatusList;
  },
  GetAllProjectStatusMetrics(state: Dashboard_state) {
    return state.allProjectsSectionsHealth.projectStatusTranslated;
  },
  GetAllProjectBoardStatusMetrics(state: Dashboard_state) {
    return state.allProjectsSectionsHealth.boardStatus;
  },
  GetAllProjectDeviceStatusMetrics(state: Dashboard_state) {
    return state.allProjectsSectionsHealth.deviceStatusTranslated;
  },
  GetAllProjectDeviceAdditionalStatusMetrics(state: Dashboard_state) {
    return state.allProjectsSectionsHealth.deviceAdditionalStatusTranslated;
  },
  GetAllManualDevices(state: Dashboard_state) {
    return state.allProjectsSectionsHealth.manualDeviceList;
  },
  GetPaginationOfAllDevices(state: Dashboard_state) {
    return state.allProjectsSectionsHealth.getPagination;
  },
  GetDeviceStateMetric(state: Dashboard_state) {
    return state.deviceStateMetric.transformToHealthChartItems();
  },
  GetDeviceStateColors(state: Dashboard_state) {
    return state.deviceStateMetric.getColorForChartItems();
  },
  GetTotalDeviceCount(state: Dashboard_state) {
    return state.deviceStateMetric.getTotalDevices();
  },
  GetUsersStats(state: Dashboard_state) {
    return state.usersStats.sessionDetailList;
  },
  GetProjectsInCongestionMode(state: Dashboard_state) {
    return state.allProjectsSectionsHealth.projectsInCongestion;
  }
};

const mutations: MutationTree<Dashboard_state> = {
  SetSectionList(state: Dashboard_state, projectHealth: ProjectHealthInterface) {
    state.allProjectsSectionsHealth = projectHealth;
  },
  SetDeviceStateMetric(state: Dashboard_state, deviceStateMetrics: DeviceStateMetric) {
    state.deviceStateMetric = deviceStateMetrics;
  },
  SetUsersStats(state: Dashboard_state, UsersStats: UsersStats) {
    state.usersStats = UsersStats;
  }
};

const actions: ActionTree<Dashboard_state, AppState> = {
  async GET_ALL_PROJECTS_SECTIONS_DASHBOARD(
    { commit, rootGetters }: ActionContext<Dashboard_state, AppState>,
    pageNumber: number
  ) {
    try {
      let res: any;
      if (rootGetters.isUserAdmin || rootGetters.isUserProjectManager || rootGetters.isConstructionManager) {
        res = await Projects.allSectionsStatusDashboard(pageNumber);
      } else {
        res = await Projects.allSectionsStatusDashboardByUser(
          pageNumber,
          (router as any).app.$keycloak.tokenParsed.sub
        );
      }
      if (res.status === 200) {
        const ProjectsHealth = new ProjectHealthInterface(res.data);
        commit("SetSectionList", ProjectsHealth);
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },
  async GET_INVENTORY_DEVICE_STATE_DASHBOARD({ commit }: ActionContext<Dashboard_state, AppState>, pageNumber: number) {
    try {
      const devicesStates = await InventoryStates.allStates();
      const res = await InventoryStatesHealth.getMetrics();
      if (res.status === 200) {
        const DeviceStateMetrics = new DeviceStateMetric(res.data.deviceStateMetrics);
        DeviceStateMetrics.setAllStates(devicesStates.data);
        commit("SetDeviceStateMetric", DeviceStateMetrics);

        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },
  async GET_USERS_STATS_DASHBOARD({ commit }: ActionContext<Dashboard_state, AppState>) {
    try {
      const res = await ibomadeUserService.getUsersStats();
      if (res.status === 200) {
        const formattedUsersStats = new UsersStats(res.data);
        commit("SetUsersStats", formattedUsersStats);
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  }
};

const namespaced: boolean = true;
export default {
  namespaced,
  state: new Dashboard_state(),
  actions,
  getters,
  mutations
};
