import ibomadeUserService from "@/services/ibomadeUserService";
export class UserListState {
  allUsers: Array<User> = [];
}
export interface User {
  email: string;
  emailVerified: boolean;
  enabled: boolean;
  fistName: string;
  id: string;
  lastName: string;
  username: string;
  roles?: string[];
}

const getters = {
  getAllUsers(state: UserListState) {
    return state.allUsers.length;
  },
  userListGetActiveUsers(state: UserListState) {
    return state.allUsers.filter((user: User) => {
      return user.emailVerified && user.enabled;
    }).length;
  },
  userListGetAllBannedUsers(state: UserListState) {
    return state.allUsers.filter((user: User) => {
      return user.emailVerified && !user.enabled;
    }).length;
  },
  userListGetAllPendingUsers(state: UserListState) {
    return state.allUsers.filter((user: User) => {
      return !user.emailVerified && user.enabled;
    }).length;
  },
  userListGetAllInactiveUsers(state: UserListState) {
    return state.allUsers.filter((user: User) => {
      return !user.emailVerified && !user.enabled;
    }).length;
  },
  userListGetNotAdminUsers(state: UserListState) {
    return state.allUsers;
  }
};

const mutations = {
  setUserList(state: UserListState, userList: Array<User>) {
    state.allUsers = userList;
  }
};

const actions = {
  async getAllUserFromBackEnd({ commit }: any) {
    try {
      const res = await ibomadeUserService.getUserList();
      commit("setUserList", res);
    } catch (error) {
      return error.response.data.message;
    }
  }
};

export default {
  state: new UserListState(),
  getters,
  mutations,
  actions
};
