import { GetterTree, MutationTree, ActionTree, ActionContext } from "vuex";
import ibomadeUserService from "@/services/ibomadeUserService";
import { Role } from "@/interface/RoleInterface";
import i18n from "@/i18n";
import { AppState } from "@/stores/state";

export class RoleState {
  public roleList: Role[] = [];
}

const getters: GetterTree<RoleState, AppState> = {
  getRoleList(state: RoleState) {
    return state.roleList;
  },
  getOnlyRoleUser(state: RoleState) {
    return state.roleList.filter((role) => role.name === "user");
  }
};
const mutations: MutationTree<RoleState> = {
  setUserListRole(state: RoleState, rolesList: Role[]) {
    state.roleList = rolesList;
  }
};
const actions: ActionTree<RoleState, AppState> = {
  async getAllRole({ commit }: ActionContext<RoleState, AppState>) {
    try {
      const res = await ibomadeUserService.getUserRoles();
      const formattedRole = res.map((item: Role) => {
        const haveTranslations = item.name in (i18n as any).t("roles.labels");
        if (haveTranslations) item.label = (i18n as any).t(`roles.labels.${item.name}`);
        else item.label = item.name;
        return item;
      });
      commit("setUserListRole", formattedRole);
    } catch (error) {
      return error;
    }
  },
  async addRoleToList({ dispatch }: ActionContext<RoleState, AppState>, newRole: Role) {
    try {
      const updateUserList = await ibomadeUserService.createUserRole(newRole);
      if (updateUserList.status === 201) {
        dispatch("getAllRole");
        return updateUserList;
      } else {
        return updateUserList;
      }
    } catch (error) {
      return error;
    }
  },
  async deleteRole({ dispatch }: ActionContext<RoleState, AppState>, id: string) {
    const deleteStatus = await ibomadeUserService.deleteUserRole(id);
    if (deleteStatus.status === 204) {
      dispatch("getAllRole");
      return deleteStatus;
    } else {
      return deleteStatus;
    }
  }
};

export default {
  state: new RoleState(),
  getters,
  mutations,
  actions
};
