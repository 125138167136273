import ModelUser from "@/model/ModelUser";
import { ModalInformation } from "@/interface/projectsInterface";
import ArchiveProjectsList from "@/model/ArchiveProjectsList";
import {
  ProjectStatus,
  Scene,
  Types,
  SectionDisplayState,
  DisplayInformation,
  ProjectThreshold,
  CameraSettings,
  CurrentRowOpen
} from "@/interface/projectsInterface";
import Project, { TrafficCountStats } from "@/model/Project";
import Section from "@/model/ModelSection";
import SectionState from "@/model/ModelSectionState";
import { ProjectStoreClass } from "./index";
import { Type } from "@/interface/DeviceInterface";

export default {
  ResetProject(state: ProjectStoreClass) {
    state.currentProject = new Project();
    state.sectionDetails = new Section();
    state.currentProjectSectionList = [];
    state.currentUserCanViewProjectDetails = false;
  },
  ResetSectionList(state: ProjectStoreClass) {
    state.currentProjectSectionList = [];
  },
  ResetInformationModal(state: ProjectStoreClass) {
    state.currentSectionState = new SectionState();
    state.currentSectionTrafficCount = new TrafficCountStats();
  },
  SetProjectList(state: ProjectStoreClass, projectList: Array<Project>) {
    state.ProjectsList = projectList;
  },
  SetStatusList(state: ProjectStoreClass, statusList: Array<ProjectStatus>) {
    state.projectStatusList = statusList;
  },
  setDetailsOfProject(state: ProjectStoreClass, currentProject: Project) {
    state.currentProject = currentProject;
  },
  DeleteProject(state: ProjectStoreClass, id: number) {
    state.ProjectsList = state.ProjectsList.filter((project) => {
      return project.id !== id;
    });
  },
  SetUsersInTheProject(state: ProjectStoreClass, userList: ModelUser[]) {
    state.userInProject = userList;
  },
  SetProjectScenes(state: ProjectStoreClass, scenes: Array<Scene>) {
    state.projectScenes = scenes;
  },
  SetActiveScene(state: ProjectStoreClass, activeScene: Scene) {
    state.activeScene = activeScene;
  },
  // types
  SetProjectTypes(state: ProjectStoreClass, typesList: Types[]) {
    state.projectTypes = typesList;
  },
  // Section
  SetSectionList(state: ProjectStoreClass, sectionList: Array<Section>) {
    state.currentProjectSectionList = sectionList;
  },
  SetSectionTypes(state: ProjectStoreClass, sectionTypesList: [Types]) {
    state.sectionTypesList = sectionTypesList;
  },
  SetSectionSubTypes(state: ProjectStoreClass, sectionSubTypesList: [Types]) {
    state.sectionTypesList = sectionSubTypesList;
  },
  SetSectionDetail(state: ProjectStoreClass, sectionDetail: Section) {
    state.sectionDetails = sectionDetail;
  },
  ResetSectionDetail(state: ProjectStoreClass) {
    state.sectionDetails = new Section();
  },
  SetIntervalList(state: ProjectStoreClass, intervalList: []) {
    state.currentSectionInterval = intervalList;
  },
  // Section state
  SetSectionState(state: ProjectStoreClass, sectionState: SectionState) {
    state.currentSectionState = sectionState;
  },
  SetSectionsStateMap(state: ProjectStoreClass, sectionState: Array<SectionState>) {
    state.allSectionsStatesForTheMap = sectionState;
  },
  ResetSectionStateMap(state: ProjectStoreClass, value: []) {
    state.allSectionsStatesForTheMap = value;
  },
  //Display List
  SetDisplayTypes(state: ProjectStoreClass, displayTypeList: []) {
    state.displayTypesList = displayTypeList;
  },
  // Display State
  setCurrentDisplayStatus(state: ProjectStoreClass, currentDisplayStatus: SectionDisplayState) {
    state.currentDisplayState = currentDisplayStatus;
  },
  SetDisplayInformation(state: ProjectStoreClass, displayInfo: DisplayInformation) {
    state.displayInformation = displayInfo;
  },
  // Manual override
  SetManualOverrideSignOptions(state: ProjectStoreClass, overrideOptions: []) {
    state.manualOverrideSignOptions = overrideOptions;
  },
  // Project threshold table
  SetCurrentThreshold(state: ProjectStoreClass, thresholdData: ProjectThreshold) {
    state.currentProjectThreshold = thresholdData;
  },
  SetCurrentThresholdTable(state: ProjectStoreClass, thresholdTableData: []) {
    state.currentProjectThresholdTable = thresholdTableData;
  },
  SetCameraSectionLatestPicture(state: ProjectStoreClass, latestPictureData: any) {
    state.cameraSectionLatestPicture = latestPictureData;
  },
  ResetCameraSectionLatestPicture(state: ProjectStoreClass) {
    state.cameraSectionLatestPicture = null;
  },
  SetCameraSectionSettings(state: ProjectStoreClass, CameraSectionSettings: CameraSettings) {
    state.currentCameraSectionSettings = CameraSectionSettings;
  },
  SetCurrentRowOpenInTheProjectList(state: ProjectStoreClass, rowOpen: CurrentRowOpen) {
    state.currentRowOpenInTheProjectList = rowOpen;
  },
  SetCurrentRowOpenMainProjectInTheProjectList(state: ProjectStoreClass, rowOpen: CurrentRowOpen) {
    state.currentRowOpenMainProjectInTheProjectList = rowOpen;
  },

  /*----------  Set country list  ----------*/
  SetCountryList(state: ProjectStoreClass, countryList: Array<object>) {
    state.allAvailableCountrysOptions = countryList;
  },

  /*----------  Archived Projects  ----------*/
  SetArchivedProjectsList(state: ProjectStoreClass, archivedProjectList: ArchiveProjectsList) {
    state.archivedProjectsList = archivedProjectList;
  },

  /*----------  Section traffic stats  ----------*/
  SetSectionTrafficStats(state: ProjectStoreClass, sectionStats: TrafficCountStats) {
    state.currentSectionTrafficCount = sectionStats;
  },
  /*----------  Section traffic stats  ----------*/
  setCanViewProjectDetails(state: ProjectStoreClass, currentUserCanViewProjectDetails: boolean) {
    state.currentUserCanViewProjectDetails = currentUserCanViewProjectDetails;
  },
  SET_MODAL_OPEN(
    state: ProjectStoreClass,
    modalInformation: ModalInformation = { sectionId: 0, position: "boardOne" }
  ) {
    state.modalInfo = modalInformation;
  }
};
