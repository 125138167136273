import { AppState } from "@/stores/state";
import i18n from "@/i18n.ts";
import { ActionContext, ActionTree, MutationTree, GetterTree, Module } from "vuex";

import { SystemLogsService } from "@/services/SystemLogs";

interface UserLog {
  timestamp: Date;
  user: string;
  activity: string;
  project: string;
  message: string;
}
export interface LogsPagination {
  totalEntries: number;
  pageCount: number;
  pageSize: number;
}

class UserLogs {
  entries: Array<UserLog> = [];
  totalEntries: number = 1;
  pagesCount: number = 0;
  pageSize: number = 10;
  constructor(resUsersLogs?: any) {
    Object.assign(this, resUsersLogs);
  }

  public get GetUsersLog(): Array<UserLog> {
    return this.entries;
  }
  public get getPagination() {
    return {
      totalEntries: this.totalEntries,
      pageCount: this.pagesCount,
      pageSize: this.pageSize
    };
  }

  public get formattedDataToDisplay() {
    const formattedData: Array<string> = [];
    this.GetUsersLog.forEach((item) => {
      const formatted = `
      <span class='d-inline-block mr-1 font-weight-bold' style='color:#2F353A'>${i18n.d(
        new Date(item.timestamp),
        "long"
      )}:</span> 
            <span class=' mr-2  font-weight-bold' style='color:#2F353A'>
            ${item.activity}
            </span> 
            <span class=' w-auto bg-white ' style='color:#2F353A'> ${item.user} </span>
            <span class='' style='color:#2F353A'>
            ${item.project !== "null" ? "project id " + item.project : ""}</span>
            <br/>  
            <span class='d-inline-block mr-2 ' >${item.message}</span>
      `;

      formattedData.push(formatted);
    });
    return formattedData;
  }
}

export class SystemLogs_store {
  UserLogs = new UserLogs();
}
const getters: GetterTree<SystemLogs_store, AppState> = {
  GetUserLogs(state: SystemLogs_store) {
    return state.UserLogs.GetUsersLog;
  },
  getUserLogPagination(state: SystemLogs_store) {
    return state.UserLogs.getPagination;
  },
  getFormattedEntryData(state: SystemLogs_store) {
    return state.UserLogs.formattedDataToDisplay;
  }
};

const mutations: MutationTree<SystemLogs_store> = {
  SetUserLogs(state: SystemLogs_store, usersLogsRes: UserLogs) {
    state.UserLogs = usersLogsRes;
  }
};

const actions: ActionTree<SystemLogs_store, AppState> = {
  async GET_ALL_USERS_LOGS(
    { commit }: ActionContext<SystemLogs_store, AppState>,
    logOptions: { pageToGo: number; perPage: number } = { pageToGo: 0, perPage: 100 }
  ) {
    try {
      const res = await SystemLogsService.GetAllUsersLogs(logOptions.pageToGo, logOptions.perPage);
      if (res.status === 202) {
        const responseUserLog = new UserLogs(res.data);
        commit("SetUserLogs", responseUserLog);
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  }
};

const namespaced: boolean = true;
export const SystemLogs: Module<SystemLogs_store, AppState> = {
  namespaced,
  state: new SystemLogs_store(),
  actions,
  getters,
  mutations
};
