import { AppState } from "@/stores/state";
import { ActionContext, ActionTree, MutationTree, GetterTree } from "vuex";
import { ProjectProfilesService } from "@/services/projectService";

import { ProjectProfile as projectProfileInterface, Interval } from "@/interface/projectsInterface";

class ProjectProfiles {
  allProjectProfiles: Array<object> = [];

  constructor(resUsersLogs?: any) {
    Object.assign(this, resUsersLogs);
  }
}

export class ProjectProfile_Store {
  allProjectProfiles: Array<projectProfileInterface> = [];
  currentProfileDetails = new projectProfileInterface();
  currentProjectProfiles: Array<projectProfileInterface> = [];

  currentSectionProfile: null | projectProfileInterface = null;

  currentProfileIntervals: Array<Interval> = [];
}
const getters: GetterTree<ProjectProfile_Store, AppState> = {
  AllProfiles(state: ProjectProfile_Store) {
    return state.allProjectProfiles;
  },
  GetAllProfilesForCurrentProject(state: ProjectProfile_Store) {
    return state.currentProjectProfiles;
  },
  GetProfileToUpdate(state: ProjectProfile_Store) {
    return state.currentProfileDetails;
  },
  GetCurrentSectionProfile(state: ProjectProfile_Store) {
    return state.currentSectionProfile;
  },
  GetCurrentProfileIntervals(state: ProjectProfile_Store) {
    return state.currentProfileIntervals;
  }
};

const mutations: MutationTree<ProjectProfile_Store> = {
  RESET_EDIT_PROFILE(state: ProjectProfile_Store) {
    state.currentProfileDetails = new projectProfileInterface();
  },
  RESET_SECTION_PROFILE(state: ProjectProfile_Store) {
    state.currentSectionProfile = null;
    state.currentProfileIntervals = [];
  },
  SetAllProfiles(state: ProjectProfile_Store, allProfiles: Array<projectProfileInterface>) {
    state.allProjectProfiles = allProfiles;
  },
  SetCurrentProjectProfiles(state: ProjectProfile_Store, currentProjectProfiles: Array<projectProfileInterface>) {
    state.currentProjectProfiles = currentProjectProfiles;
  },
  setCurrentProfileDetail(state: ProjectProfile_Store, currentProfileDetails: projectProfileInterface) {
    state.currentProfileDetails = currentProfileDetails;
  },
  setCurrentSectionProfile(state: ProjectProfile_Store, currentSectionProfile: projectProfileInterface) {
    state.currentSectionProfile = currentSectionProfile;
  },
  setCurrentProfileIntervals(state: ProjectProfile_Store, currentProfileIntervals: Array<Interval>) {
    state.currentProfileIntervals = currentProfileIntervals;
  }
};

const actions: ActionTree<ProjectProfile_Store, AppState> = {
  async GET_ALL_PROJECT_PROFILES({ commit }: ActionContext<ProjectProfile_Store, AppState>) {
    try {
      const res = await ProjectProfilesService.GetAllProjectProfiles();
      if (res.status === 200) {
        commit("SetAllProfiles", res.data);
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },
  async GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT(
    { commit }: ActionContext<ProjectProfile_Store, AppState>,
    projectId: number
  ) {
    try {
      const res = await ProjectProfilesService.GetAllProjectProfilesForProject(projectId);
      if (res.status === 200) {
        commit("SetCurrentProjectProfiles", res.data);
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },
  async CREATE_NEW_PROFILE(
    { dispatch }: ActionContext<ProjectProfile_Store, AppState>,
    newProfile: projectProfileInterface
  ) {
    try {
      const res = await ProjectProfilesService.CreateNewProjectProfile(newProfile);
      if (res.status === 201) {
        dispatch("GET_ALL_PROJECT_PROFILES");
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },
  async UPDATE_PROJECT_PROFILE(
    { dispatch }: ActionContext<ProjectProfile_Store, AppState>,
    { id, profileToUpdate }: { id: string; profileToUpdate: projectProfileInterface }
  ) {
    try {
      const res = await ProjectProfilesService.UpdateProjectProfile(id, profileToUpdate);
      if (res.status === 200) {
        dispatch("GET_ALL_PROJECT_PROFILES");
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },
  async REMOVE_PROFILE(
    { dispatch }: ActionContext<ProjectProfile_Store, AppState>,
    { profileId, projectId }: { profileId: string; projectId: number }
  ) {
    try {
      const res = await ProjectProfilesService.DeleteProjectProfile(profileId);
      if (res.status === 204) {
        dispatch("GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT", projectId);
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },
  async GET_PROFILE_DETAILS({ commit }: ActionContext<ProjectProfile_Store, AppState>, id: string) {
    try {
      const res = await ProjectProfilesService.GetProjectProfileDetails(id);
      if (res.status === 200) {
        const profileToEdit = new projectProfileInterface(res.data);
        commit("setCurrentProfileDetail", profileToEdit);
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },
  async GET_CURRENT_SECTION_PROFILE({ commit }: ActionContext<ProjectProfile_Store, AppState>, id: number) {
    try {
      const res = await ProjectProfilesService.GetSectionProfile(id);
      if (res.status === 200) {
        const sectionProfile = new projectProfileInterface(res.data);
        commit("setCurrentSectionProfile", sectionProfile);
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },
  async GET_PROFILE_INTERVALS({ commit }: ActionContext<ProjectProfile_Store, AppState>, id: number) {
    try {
      const res = await ProjectProfilesService.GetIntervalsInProfile(id);

      if (res.status === 200) {
        commit("setCurrentProfileIntervals", res.data);
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  }
};

const namespaced: boolean = true;
export default {
  namespaced,
  state: new ProjectProfile_Store(),
  actions,
  getters,
  mutations
};
