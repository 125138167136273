import axios, { AxiosResponse } from "axios";
import router from "@/router";
import { MeasureReportOptions } from "@/interface/projectsInterface";
export interface TrafficVolumeData {
  sectionId: number;
  totalVolumePerHour: number;
}

const TrafficCountDetails = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_EVALUATOR,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async GetTrafficCount(id: string, volumeVariant: string) {
    const res = await this.createAxiosInstance().get(
      `/api/v1/traffic-count/${id}?volumeAnalysisVariant=${volumeVariant}`
    );
    return res;
  },
  async GetTrafficCountReport(reportOptions: MeasureReportOptions) {
    const res: AxiosResponse = await this.createAxiosInstance().get(
      `/api/v1/traffic-count/${reportOptions.sectionId}/${reportOptions.boardId}?from=${reportOptions.startDate}&to=${reportOptions.endDate}`,
      {
        headers: {
          Accept: "text/plain"
        }
      }
    );

    return res;
  },

  async GetTrafficCountVolumesReport(reportOptions: MeasureReportOptions) {
    const res: AxiosResponse = await this.createAxiosInstance().get(
      `/api/v1/traffic-count-volumes/${reportOptions.sectionId}?from=${reportOptions.startDate}&to=${reportOptions.endDate}`,
      {
        headers: {
          Accept: "text/plain"
        }
      }
    );
    return res;
  },
  async GetVolumeByProject(projectId: number): Promise<AxiosResponse<TrafficVolumeData[]>> {
    return await this.createAxiosInstance().get(`/api/v1/traffic-volumes/project/${projectId}`);
  },
  async GetDeviceHistoricalData(
    deviceId: string,
    timeFrame: string = "1H"
  ): Promise<AxiosResponse<Record<string, Array<number>>>> {
    return await this.createAxiosInstance().get(`api/v1/battery-statistics/${deviceId}?timeFrame=${timeFrame}`);
  }
};

export { TrafficCountDetails };
