import UserAccess from "@/model/User/UserAccess";
import router from "@/router";
import ibomadeUserService from "@/services/ibomadeUserService";
import ModelUser from "@/model/ModelUser";

export class CurrentUserState {
  currentUser: ModelUser = new ModelUser();
  isAdmin: boolean = false;
  isUserAgency: boolean = false;
  isProjectManager = false;
  isConstructionManager = false;
  access = new UserAccess();
}

const getters = {
  getCurrentUser(state: CurrentUserState): ModelUser {
    return state.currentUser;
  },
  isUserAdmin(state: CurrentUserState): boolean {
    return state.isAdmin;
  },
  isUserAgency(state: CurrentUserState): boolean {
    return state.isUserAgency;
  },
  isUserProjectManager(state: CurrentUserState): boolean {
    return state.isProjectManager;
  },
  isConstructionManager(state: CurrentUserState): boolean {
    return state.isConstructionManager;
  },
  getUserAccess(state: CurrentUserState) {
    return state.access;
  }
};
const mutations = {
  setCurrentUser(state: any, user: any) {
    state.currentUser = user;
  },
  resetUser(state: any) {
    if (state.currentUser !== null) {
      state.currentUser = null;
    }
  },
  setCurrentUserPermission(state: CurrentUserState, value: boolean) {
    state.isAdmin = value;
  },
  setCurrentUserPermissionManager(state: CurrentUserState, isUserAgency: boolean) {
    state.isUserAgency = isUserAgency;
  },
  setCurrentUserPermissionProjectManager(state: CurrentUserState, isProjectManager: boolean) {
    state.isProjectManager = isProjectManager;
  },
  setCurrentUserPermissionConstructionManager(state: CurrentUserState, isConstructionManager: boolean) {
    state.isConstructionManager = isConstructionManager;
  },
  setAccess(state: CurrentUserState, access: UserAccess) {
    state.access = access;
  }
};
const actions = {
  async getUserInfo({commit}: any, id: string) {
    const res = await ibomadeUserService.getUserProfile(id);

    if (res.status === 200) {
      const user = new ModelUser(res.data);
      commit("setCurrentUser", user);
      return res;
    } else {
      commit("resetUser");
      return res;
    }
  },
  resetUser({commit}: any) {
    commit("resetUser");
  },
  checkUserAdmin({commit}: any) {
    const isAdmin = (router as any).app.$keycloak.hasRealmRole("admin");
    const isUserAgency = (router as any).app.$keycloak.hasRealmRole("agency");
    const isProjectManager = (router as any).app.$keycloak.hasRealmRole("project_manager");
    const isConstructionManager = (router as any).app.$keycloak.hasRealmRole("construction_manager");
    const userAccess = new UserAccess();

    if (isAdmin) {
      commit("setCurrentUserPermission", isAdmin);
      userAccess.setAgencyAccess();
      userAccess.setProjectManagerAccess();
      userAccess.setAdminAccess();
      userAccess.setConstructionManagerAccess();
    } else if (isProjectManager) {
      commit("setCurrentUserPermissionProjectManager", isProjectManager);
      userAccess.setAgencyAccess();
      userAccess.setProjectManagerAccess();
    } else if (isConstructionManager) {
      commit("setCurrentUserPermissionConstructionManager", isConstructionManager);
      userAccess.setAgencyAccess();
      userAccess.setConstructionManagerAccess();
    } else if (isUserAgency) {
      commit("setCurrentUserPermissionManager", isUserAgency);
      userAccess.setAgencyAccess();
    }
    commit("setAccess", userAccess);
  }
};

export default {
  state: new CurrentUserState(),
  getters,
  mutations,
  actions
};
