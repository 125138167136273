import { MutationTree, ActionTree, GetterTree, ActionContext } from "vuex";
import { DeviceTypes, DeviceEndPoint, InventoryStates } from "@/services/InventoryService";
import { Device, Type, DeviceProject } from "@/interface/DeviceInterface";
import { ProjectSectionDetails } from "@/services/projectService";
import { AppState } from "@/stores";
import { DeviceState } from "@/model/SingleDeviceModal";
import i18n from "@/i18n";

export interface DeviceProjectDetails {
  projectId: number;
  projectName: string;
}

type DeviceSates = "NEW" | "AVAILABLE" | "MAINTENANCE" | "IN_FIELD" | "BOOKED" | "ARCHIVED";

class inventoryList {
  allDevices: Array<Device> = [];
  localCopy: Array<Device> = [];

  constructor(inventoryList?: Array<Device>) {
    if (inventoryList) {
      this.allDevices = inventoryList;
      this.localCopy = inventoryList;
    }
  }

  filterByType(type: Type | null) {
    this.allDevices = this.localCopy;
    if (type) {
      this.allDevices = this.allDevices.filter((item) => item.type.name === type.name);
    }
  }

  filterByStatus(state: string) {
    this.allDevices = this.allDevices.filter((item) => item.state === state);
  }

  resetAll() {
    this.allDevices = this.localCopy;
  }

  public getAllAvailableAndCurrentDevice(id: string) {
    const allAvailableDevices: Array<Device> = this.allDevices.filter((device: Device) => {
      return device.type.name === "main controller" && device.state === "AVAILABLE";
    });
    if (id) {
      const currentDevice: Device | undefined = this.allDevices.find((item: Device) => item.serialNumber === id);
      if (currentDevice) {
        allAvailableDevices.unshift(currentDevice);
      }
    }
    return allAvailableDevices.sort((a, b) => parseFloat(a.serialNumber) - parseFloat(b.serialNumber));
  }

  public getAllBookedAndInField(): Array<Device> {
    const allBookedAndInField: Array<Device> = this.allDevices.filter((device: Device) => {
      return device.type.name === "main controller" && (device.state === "BOOKED" || device.state === "IN_FIELD");
    });

    return allBookedAndInField.sort((a, b) => parseFloat(a.serialNumber) - parseFloat(b.serialNumber));
  }

  public getDevicesForMeasureAndCamera(): Array<Device> {
    return Array.from(new Set([...this.getAllAvailableAndCurrentDevice(""), ...this.getAllBookedAndInField()]));
  }
}

export class InventoryState {
  inventoryList = new inventoryList([]);
  archivedDevices: Array<Device> = [];
  deviceTypes: Array<object> = [];
  deviceStates: DeviceSates[] = [];
  error: any;
  currentDeviceProjectsDetails: DeviceProjectDetails[] = [];
}

export class NewDeviceState {
  id: string = "";
  newStatus: string = "";
}

const getters: GetterTree<InventoryState, AppState> = {
  getErrorFromInventoryList(state: InventoryState) {
    return state.error;
  },
  getAllDevices(state: InventoryState) {
    return state.inventoryList.allDevices;
  },
  getAllBatteryDevices(state: InventoryState) {
    return state.inventoryList.allDevices.filter((device: Device) => {
      return device.type.name === "battery";
    });
  },
  getAllBatteryDevicesInProject(state: InventoryState) {
    return (projectId: number) =>
      state.inventoryList.allDevices.filter((device: Device) => {
        return (
          device.type.name === "battery" &&
          device.projects.some((project: DeviceProject) => {
            return project.id === projectId;
          })
        );
      });
  },
  getAllBatteryDevicesNotInProject(state: InventoryState) {
    const possibleStateValues = [
      DeviceState.AVAILABLE.toString(),
      DeviceState.IN_FIELD.toString(),
      DeviceState.BOOKED.toString()
    ];
    const res = (projectId: number) =>
      state.inventoryList.allDevices.filter((device: Device) => {
        return (
          device.type.name === "battery" &&
          possibleStateValues.includes(device.state) &&
          (device.projects.length === 0 ||
            device.projects.some((project: DeviceProject) => {
              return project.id !== projectId;
            }))
        );
      });
    return res;
  },
  getAllNewDevices(state: InventoryState) {
    return state.inventoryList.allDevices.filter((device: Device) => {
      return device.type.name === "main controller" && device.state === "AVAILABLE";
    });
  },
  getAllNewAndBookedDevices(state: InventoryState) {
    return (id: string) => {
      return state.inventoryList.getAllAvailableAndCurrentDevice(id);
    };
  },
  getAllDevicesForMeasureAndCamera(state: InventoryState) {
    return state.inventoryList.getDevicesForMeasureAndCamera();
  },
  getAllBookedAndInFieldDevices(state: InventoryState) {
    return state.inventoryList.getAllBookedAndInField();
  },
  getDeviceBySerialNumber(state: InventoryState) {
    return (id: string) => state.inventoryList.allDevices.find((device: any) => device.serialNumber === id);
  },
  getDeviceById(state: InventoryState) {
    return (id: string) => state.inventoryList.allDevices.find((device: any) => device.id == id);
  },
  getAllDeviceTypes(state: InventoryState) {
    return state.deviceTypes;
  },
  getAllDeviceStates(state: InventoryState) {
    return state.deviceStates;
  },
  getAllArchivedDevices(state: InventoryState) {
    return state.archivedDevices;
  },
  getCurrentDeviceProjectsDetails(state: InventoryState) {
    return state.currentDeviceProjectsDetails;
  }
};
const mutations: MutationTree<InventoryState> = {
  setDeviceList(state: InventoryState, listOfDevices: inventoryList) {
    state.inventoryList = listOfDevices;
  },
  archiveDeviceFromList(state: InventoryState, id: string) {
    state.inventoryList.allDevices = state.inventoryList.allDevices.filter((device: Device) => device.id !== id);
  },
  setTypes(state: InventoryState, types: Type[]) {
    state.deviceTypes = types;
  },
  setStates(state: InventoryState, states: DeviceSates[]) {
    state.deviceStates = states;
  },
  resetErrorValue(state: InventoryState, value: object | boolean) {
    state.error = value;
  },
  changeStatusOfTheDevice(state: InventoryState, newStatusOfTheDevice: NewDeviceState) {
    state.inventoryList.allDevices = state.inventoryList.allDevices.map((device: Device) => {
      if (device.id == newStatusOfTheDevice.id) {
        device.state = newStatusOfTheDevice.newStatus;
      }
      return device;
    });
  },
  setArchivedList(state: InventoryState, deviceList: Array<Device>) {
    state.archivedDevices = deviceList;
  },
  FilterByType(state: InventoryState, type: Type) {
    state.inventoryList.filterByType(type);
  },
  setCurrentDeviceProjectsDetails(state: InventoryState, projectDetails: DeviceProjectDetails[]) {
    state.currentDeviceProjectsDetails = projectDetails;
  }
};

const actions: ActionTree<InventoryState, AppState> = {
  async getAllDevicesFromBackEnd({ commit }: ActionContext<InventoryState, AppState>) {
    try {
      const res = await DeviceEndPoint.getAllDevices();
      if (res.status === 200) {
        const list = new inventoryList(res.data.items);
        commit("setDeviceList", list);
      } else {
        return res;
      }
    } catch (error) {
      return error.response.data.message;
    }
  },
  async createNewDevice({ dispatch, commit }: ActionContext<InventoryState, AppState>, newDevice: Device) {
    try {
      commit("resetErrorValue", false);
      const res = await DeviceEndPoint.createNewDevice(newDevice);
      if (res.status === 202) {
        dispatch("getAllDevicesFromBackEnd");
        return res;
      } else {
        return res;
      }
    } catch (error) {
      commit("resetErrorValue", {
        class: "danger",
        msg: error.response.data.message
      });
      return error;
    }
  },
  async archiveDevice({ dispatch }: ActionContext<InventoryState, AppState>, id: string) {
    try {
      const res = await DeviceEndPoint.archiveDeviceFromInventory(id);
      if (res.status === 202) {
        dispatch("getAllDevicesFromBackEnd");
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async rebootRouter({ dispatch }: ActionContext<InventoryState, AppState>, serialNumber: string) {
    try {
      const res = await DeviceEndPoint.rebootRouterFromInventory(serialNumber);
      if (res.status === 202) {
        dispatch("getAllDevicesFromBackEnd");
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async getAllTypesFromBackEnd({ commit }: ActionContext<InventoryState, AppState>) {
    try {
      const res = await DeviceTypes.allTypes();
      const { types } = res.data;
      commit("setTypes", types);
    } catch (error) {
      return error.response.data.message;
    }
  },
  async getAllStatesFromBackEnd({ commit }: ActionContext<InventoryState, AppState>) {
    try {
      const res = await InventoryStates.allStates();
      commit("setStates", res.data);
    } catch (error) {
      return error.response.data.message;
    }
  },
  resetErrors({ commit }: ActionContext<InventoryState, AppState>, value: boolean | object) {
    commit("resetErrorValue", value);
  },
  async updateDevice({ commit, dispatch }: any, data: Device) {
    try {
      const res = await DeviceEndPoint.updateDevice(data);
      if (res.status === 202) {
        dispatch("getAllDevicesFromBackEnd");
      }
      return res;
    } catch (error) {
      commit("resetErrorValue", {
        class: "danger",
        // Backend need to send a error message
        msg: error.response.data.message
      });
      return error.response.data.message;
    }
  },
  async updateGpsPositionDevice({ commit, dispatch }: any, serialNumber: string) {
    try {
      const res = await DeviceEndPoint.updateGpsPositionDevice(serialNumber);
      if (res.status === 202) {
        dispatch("getAllDevicesFromBackEnd");
      }
      return res;
    } catch (error) {
      commit("resetErrorValue", {
        class: "danger",
        // Backend need to send a error message
        msg: error.response.data.message
      });
      return error.response.data.message;
    }
  },
  async getAllArchivedFromBackEnd({ commit }: ActionContext<InventoryState, AppState>) {
    try {
      const res = await DeviceEndPoint.getAllArchived();
      if (res.status === 200) {
        commit("setArchivedList", res.data.items);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error.response.data.message;
    }
  },
  async SearchDevices({ commit }: ActionContext<InventoryState, AppState>, searchTerm: string) {
    try {
      const request = await DeviceEndPoint.GetSearchTerm(searchTerm);
      if (request.status === 200) {
        commit("setDeviceList", request.data);
        return request;
      } else {
        return request;
      }
    } catch (error) {
      return error;
    }
  },
  async DeleteArchivedDevice({ dispatch }: ActionContext<InventoryState, AppState>, deviceId: number) {
    try {
      const request = await DeviceEndPoint.DeleteArchivedDevice(deviceId);
      if (request.status === 200) {
        dispatch("getAllArchivedFromBackEnd");
      }
      return request;
    } catch (error) {
      return error;
    }
  },
  async GetDeviceProjectsDetails({ commit }: ActionContext<InventoryState, AppState>, serialNumber: string) {
    try {
      const res = await ProjectSectionDetails.GetSectionDetailsByDeviceSerialNumber(serialNumber);
      if (res.status === 200 && res.data.assignments) {
        commit("setCurrentDeviceProjectsDetails", res.data.assignments);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },
  async UpdateBatteryList(
    context: ActionContext<InventoryState, AppState>,
    payload: { projectId: string; batteryList: Array<string> }
  ): Promise<any> {
    const { dispatch, commit } = context;
    try {
      const res = await DeviceEndPoint.UpdateBatteryList(payload.projectId, payload.batteryList);
      if (res.status === 200 && res.data.assignments) {
        dispatch("getAllDevicesFromBackEnd");
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  }
};

export default {
  state: new InventoryState(),
  getters,
  mutations,
  actions
};