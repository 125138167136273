import axios from "axios";
import router from "@/router";

const SystemSettings = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_AUTHORIZATION,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async getSystemConfig() {
    const res = await this.createAxiosInstance().get(`/api/v1/sysconfig`);
    return res;
  },

  async ToggleMaintenanceMode(maintenanceMode: object) {
    const res = await this.createAxiosInstance().put(`/api/v1/sysconfig`, maintenanceMode);
    return res;
  }
};

export { SystemSettings };
