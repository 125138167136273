const SortingOrdinalNumbers = (itemToSort: Array<object>) => {
  if (typeof itemToSort !== "object") {
    return itemToSort;
  }
  return itemToSort.sort(function (a: any, b: any) {
    return a.ordinalNumber - b.ordinalNumber;
  });
};

export default SortingOrdinalNumbers;
