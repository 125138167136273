import axios, { AxiosResponse } from "axios";
import router from "@/router";
import { MainProjectSection } from "@/model/MainProject/MainProjectSection";

interface SectionListObject {}
export default class MainProjectService {
  static createAxiosInstance() {
    const MPAxiosInstance = axios.create({
      baseURL: process.env.VUE_APP_PROJECT,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return MPAxiosInstance;
  }

  static async getMainProjectById(ID: number): Promise<AxiosResponse<Record<string, MainProjectSection[]>>> {
    return await this.createAxiosInstance().get(`api/v2/section/list/${ID}`);
  }

  static async sortSectionsByPositions(projectId: number, direction: String, sortingValue: String) {
    const res = await this.createAxiosInstance().put(
      `api/v2/section/list/${projectId}/sorting/${direction}/${sortingValue}`
    );
    return res;
  }
}
