import { AxiosResponse } from "axios";
import { ReportOptions, MeasureReportOptions } from "@/interface/projectsInterface";
import { AppState } from "@/stores/state";
import { ActionContext, ActionTree, MutationTree, GetterTree, Module } from "vuex";
import { ProjectReportExport } from "@/services/projectService";
import { TrafficCountDetails } from "@/services/evaluatorService";

//TODO: This "THING"TM is also existent in PdfReportModal and ReportGenaratorModal
//      Please remove this duplication and untangle the logic when and if this is used for PDF Reports or CSV files
interface CSV {
  csvBlob?: Blob;
  name?: string;
  csvBlob2?: Blob;
  name2?: string;
}
export class ProjectReport {
  GeneratedProjectReport: CSV = {};
}

const getters: GetterTree<ProjectReport, AppState> = {
  GetGeneratedReport(state: ProjectReport) {
    return state.GeneratedProjectReport;
  }
};

const mutations: MutationTree<ProjectReport> = {
  SetProjectReport(state: ProjectReport, projectReport: object) {
    state.GeneratedProjectReport = projectReport;
  }
};

const actions: ActionTree<ProjectReport, AppState> = {
  async GET_PROJECT_REPORT({ commit }: ActionContext<ProjectReport, AppState>, ReportOptions: ReportOptions) {
    try {
      const res: AxiosResponse = await ProjectReportExport.GetGeneratedReport(ReportOptions);
      if (res.status === 202) {
        const name = res.headers["content-disposition"].replace("attachment; filename=", "").replace(/"/g, "");
        const csvBlob = new Blob([res.data], { type: "text/csv;charset=utf-8;" });
        commit("SetProjectReport", { csvBlob, name });
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  },

  // get report of measurement section which contains info about traffic counts
  async GET_MEASURESECTION_REPORT(
    { commit }: ActionContext<ProjectReport, AppState>,
    ReportOptions: MeasureReportOptions
  ) {
    try {
      const res: AxiosResponse = await TrafficCountDetails.GetTrafficCountReport(ReportOptions);
      const trafficCountVolumes: AxiosResponse = await TrafficCountDetails.GetTrafficCountVolumesReport(ReportOptions);
      if (res.status === 202) {
        const trafficCountReportFilename = res.headers["content-disposition"].replace("attachment; filename=", "").replace(/"/g, "").replace(/:/g, "-");
        const trafficCountReportData = new Blob([res.data], { type: "text/csv;charset=utf-8;" });

        const trafficVolumesReportFilename = trafficCountVolumes.headers["content-disposition"]
          .replace("attachment; filename=", "")
          .replace(/"/g, "");
        const trafficVolumesReportData = new Blob([trafficCountVolumes.data], { type: "text/csv;charset=utf-8;" });
        commit("SetProjectReport", { csvBlob: trafficCountReportData, name: trafficCountReportFilename, csvBlob2: trafficVolumesReportData, name2: trafficVolumesReportFilename });
        return res;
      }
      return res;
    } catch (error) {
      return error;
    }
  }
};

const namespaced: boolean = true;
const ProjectReportGenerator: Module<ProjectReport, AppState> = {
  namespaced,
  state: new ProjectReport(),
  actions,
  getters,
  mutations
};
export default ProjectReportGenerator;
